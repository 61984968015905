import React from 'react';
import './App.css';

class Home extends React.Component {
	constructor(props) {
		super(props);
	}
	click = (page) => {
		this.props.changePage(page);
	}
	render() {
	    return (
	    	<div className="homeWrapper">
	    		<a href="#" className={`button ${this.props.page==="new" ? "active" : ""}`} onClick={() => this.click('new')}><span className="lnr lnr-plus-circle"></span><span className="label">Activate New Phone</span></a>
	    		<a href="#" className={`button ${this.props.page==="check" ? "active" : ""}`} onClick={() => this.click('check')}><span className="lnr lnr-unlink"></span><span className="label">Check Ported Phone</span></a>
	    		<a href="#" className={`button ${this.props.page==="replenish" ? "active" : ""}`} onClick={() => this.click('replenish')}><span className="lnr lnr-cloud-sync"></span><span className="label">Replenish Account</span></a>
	    		<a href="#" className={`button ${this.props.page==="view" ? "active" : ""}`} onClick={() => this.click('view')}><span className="lnr lnr-chart-bars"></span><span className="label">View My Account</span></a>
	    		<a href="#" className="button close" onClick={() => this.click('home')}>x</a>
	    	</div>
    	);
	}
}

export default Home;
