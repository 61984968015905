import React from 'react';
import './App.css';
import Logo from './images/logo.png';

class Header extends React.Component {
	render() {
	    return (
	    	<header>
			   <img src={Logo} alt="Connekt" />
	    	</header>
    	);
	}
}

export default Header;
