import React from 'react';
import './App.css';
import InputMask from "react-input-mask";
import Popup from "reactjs-popup";
import ToggleSwitch from './ToggleSwitch.js';
import Checkout from './Checkout.js';
import Success from './images/success.png';
import Pending from './images/pending.png';
import ErrorIcon from './images/error.png';
import ImeiHint from './images/imei-hint.jpg';
import SimHint from './images/sim-hint.jpg';

class New extends React.Component {
	constructor(props) {
		super(props);
		console.log(props);
		this.state = {
			loading: false,
			step: 0,
			esn: '',
			iccid: '8914800000',
			callback: '',
			area: '',
			password: '1234',
			error: false,
			errMsg: '',
			transfer: false,
			phone: '',
			account: '',
			accountPass: '',
			plansLoaded: false,
			plans: [],
			selectedPlan: '',
			amount: 0,
			planId: '',
			cc: '',
			exp: '',
			cvv: '',
			zip: '',
			mdn: '',
			autopay: false,
			highlight: false,
			loadingMsg: '',
			mdn: ''
		};
	}
	componentDidMount() {
		let url = 'https://pvc2.approvedlink.com/CVWebService/PhoneHandler.aspx?method=getppamounts';
		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({
						plansLoaded: true,
						plans: result
					})
				})
			.catch((err) => {
				this.setState({errMsg: 'There was an error getting plan information. Contact us at 866-444-5404 for more assistance.'})
				this.setState({loading: false, error: true})
			});
	}

	//Click next
	next = () => {
		var step = this.state.step
		this.setState({loading: true})
		switch(step) {
			case 0:
				if(this.validate(step)){
					let url = "https://pvc2.approvedlink.com/CVWebService/PhoneHandler.aspx?method=verify&esn="+this.state.esn+"&iccid="+this.state.iccid+"&login=tablet1!&t=1589811345223";
					fetch(url)
						.then(res => res.text())
						.then(
							(result) => {
								console.log(result);
								if(result === 'Available'){
									this.setState({loading: false});
									this.setState({step: 1})
								} else {
									this.setState({errMsg: 'This phone isn\'t available. Try entering your information again or contact us at 866-444-5404.'})
									this.setState({loading: false, error: true})
								}
							},
							(error) => {
								console.log(error);
								this.setState({loading: false, errMsg: 'There was an error while verifying your phone. Please contact us at 866-444-5404.'});
							}
						)	

					this.setState({loading: false});
					this.setState({step: 1})

				} else {
					this.setState({loading: false});
					this.setState({error: true});
				}
				break;
			case 1:
				if(!this.state.transfer){
					if(this.validate(step)) {
						this.addSubscriber();
					} else {
						this.setState({loading: false, error: true});
					}
				} else {
					if(this.validate(step)) {
						let phone = this.state.phone.replace(/\D+/g, '');
						let url = "https://pvc2.approvedlink.com/CVWebService/PhoneHandler.aspx?method=port&phonenumber="+phone+"&esn="+this.state.esn+"&iccid="+this.state.iccid+"&account="+this.state.account+"&passcode=1234&secretcode="+this.state.password+"&login=tablet1!";
						fetch(url)
						.then(res => res.text())
						.then(
							(result) => {
								if(result === 'Success') {
									var obj = {
										IMEI: this.state.esn,
										SIM: this.state.iccid,
										Phone: this.state.phone,
										Account_Number: this.state.account,
										Account_Password: this.state.accountPass,
										Password: this.state.password,
										Callback: this.state.callback
									};
									var subject = 'New Phone Ported';
									this.handleSubmit(obj, subject);
									this.setState({loading: false, step: 3})
								} else {
									console.log(result);
									this.setState({errMsg: "There was an error porting your phone. Please recheck your entry and try again or call us at 866-444-5404."});
									this.setState({loading: false, error: true});
								}
							},
							(error) => {
								console.log(error);
								this.setState({loading: false});
							});
					} else {
						this.setState({loading: false, error: true})
					}
				}
				break;
			case 2:
				if(this.validate(step)){
					this.setState({loading: false});
					this.setState({step: 3})
				} else {
					this.setState({loading: false});
					this.setState({error: true});
				}
				break;
			default:
				break;
		}
	}

	// Validate step
	validate = (step) => {
		let returnVal = true;
		let err = [];
		switch(step) {
			case 0:
				if(this.state.esn.length < 15) {
					returnVal = false;
				}
				if(this.state.iccid.length < 20) {
					returnVal = false;
				}
				if(this.state.callback.length < 14) {
					returnVal = false;
				}
				if(!returnVal) {
					this.setState({highlight: true, errMsg: 'Oops. You forgot to fill out the highlighted fields completely.'});
				}
				break;
			case 1:
				if(this.state.esn.length < 1) {
					returnVal = false;
				}
				if(this.state.iccid.length < 1) {
					returnVal = false;
				}
				if(this.state.password.length < 1) {
					returnVal = false;
				}
				if(!this.state.transfer) {
					if(this.state.area.length < 1) {
						returnVal = false;
					}
				} else {
					if(this.state.phone.length < 1) {
						returnVal = false;
					}		
					if(this.state.account.length < 1) {
						returnVal = false;
					}					
				}
				if(!returnVal) {
					this.setState({highlight: true, errMsg: 'Oops. You forgot to fill out the highlighted fields.'});
				}
				break;
			default:
				break;
		}
		return returnVal;
	}

	// Close loading
	loadingClose = () => {
		this.setState({loading: false});
	}

	// Close error 
	errorClose = () => {
		this.setState({error: false});
	}

	// Previous button
	prev = () => {
		if(this.state.step > 0)
			this.setState({step: this.state.step - 1});
	}

	// Verify
	verify = () => {
		this.props.changePage();
	}

	// Get Number
	getNumber = (count) => {
		if(count < 3) {
			let mdnUrl = "https://pvc2.approvedlink.com/CVWebService/PhoneHandler.aspx?method=verificationesn&iccid="+this.state.iccid+"&secretcode=1234&login=121212";
			fetch(mdnUrl)
			.then(res2 => res2.text())
			.then(
				(result2) => {
					console.log(result2);
					if(result2 && result2.indexOf("MDN=") > 0){
						let subResult = result2.substring(result2.indexOf("MDN="));
						console.log(subResult);
						let mdn = subResult.substring(0, subResult.indexOf(",")).replace("MDN=", "");
						this.setState({mdn: mdn});
						this.setState({loadingMsg: ''});
						this.subscriberAdded(result2);	
					} else if(result2.indexOf("MDN:") > 0) {
						let subResult = result2.substring(result2.indexOf("MDN:"));
						let mdn = subResult.substring(subResult.indexOf("MDN:"), subResult.indexOf("MDN:") + 14).replace("MDN:", "");
						console.log(mdn);
						this.setState({mdn: mdn});
						this.setState({loadingMsg: ''});
						this.subscriberAdded(result2);		
					} else {
						// Try again
						setTimeout(() => {
							this.getNumber(count + 1);
						}, 1000);
					}
				});	
		} else {
			this.sendError("MDN not returned");
			this.setState({errMsg: "Activation failed. Please try again or call customer service at 866-444-5404."});
			this.setState({loading: false, error: true});
		}
	}


	// Add subscriber
	addSubscriber = () => {
		let url = "https://pvc2.approvedlink.com/CVWebService/PhoneHandler.aspx?method=addsubscriber&esn="+this.state.esn+"&iccid="+this.state.iccid+"&zip="+this.state.area+"&secretcode="+this.state.password+"&login=tablet1!&t=1589811365105";
		fetch(url)
			.then(res => res.text())
			.then(
				(result) => {
					console.log(result);
					this.getNumber(0);
				},
				(error) => {
					this.sendError("Error activating account");
					this.setState({errMsg: "There was an error setting up your phone. Please try again or contact us at 866-444-5404 for more details.", loadingMsg: ''});
					this.setState({loading: false, error: true});	
				}
			)
	}

	// Subscriber added
	subscriberAdded = (resp) => {
		var obj = {
			IMEI: this.state.esn,
			SIM: this.state.iccid,
			Area_Code: this.state.area,
			Password: this.state.password,
			MDN: this.state.mdn,
			Callback: this.state.callback
		};
		this.handleSubmit(obj, 'New Account Created');

		this.setState({loading: false});
		this.setState({step: 3});
	}


	sendError = (subject) => {
		var obj = {
			IMEI: this.state.esn,
			SIM: this.state.iccid,
			Area_Code: this.state.area,
			Password: this.state.password,
			MDN: this.state.mdn,
			Callback: this.state.callback
		};
		this.handleSubmit(obj, subject);
	}

	// Send email
	handleSubmit (obj, subject) {
		const templateId = 'connekt_vending_machine';
		var emailContent = '<table>';
		for (var key in obj) {
		    if (obj.hasOwnProperty(key)) {
		    	emailContent += '<tr><td>'+key+'</td><td>'+obj[key]+'</td></tr>';
		    }
		}
		emailContent += '</table>';
		this.sendFeedback(templateId, {name: emailContent, subjectText: subject})
	}
	sendFeedback (templateId, variables) {
		window.emailjs.send(
			'service_mwcbwur', templateId,
			variables
		).then(res => {
			console.log('Email successfully sent!')
		})
		// Handle errors here however you like, or use a React error boundary
		.catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
	}


	// Change input
	changeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		if(nam == 'autopay') {    
			// val = event.target.checked;
		}
		this.setState({[nam]: val});
	}

	// Change toggle input
	toggleHandler = (event) => {
		this.setState({'autopay': !this.state.autopay});
	}

	// Transfer / Activate toggle
	transfer = (trans) => {
		this.setState({transfer: trans})
	}

	// Select a plan
	choosePlan = (plan, amount, planId) => {
		this.setState({selectedPlan: plan, amount: amount, planId: planId})
	}

	// Success handler 
	success = () => {
		console.log('success');
	}

	// Error handlers
	selectError = () => {
		this.setState({errMsg: 'Please select a plan.'});	
		this.setState({error: true})
	}
	cardError = () => {
		this.setState({errMsg: 'Oops. You forgot to fill out the highlighted fields.'});
		this.setState({error: true})
	}
	chargeError = () => {
		this.setState({errMsg: 'We could not charge your card. Please try again or call us at 866-444-5404.'});
		this.setState({error: true})
	}
	error = () => {
		this.setState({errMsg: 'Uh oh. There was an unidentified error. Please try again or call us at 866-444-5404.'});
		this.setState({error: true})
	}

	// Loading Handlers
	loading = () => {
		this.setState({loading: true})
	}
	stopLoading = () => {
		this.setState({loading: false})
	}

	// Display page
	render() {
		var step = this.state.step;

		var el = '';
		var step2 = '';
		if(!this.state.transfer){
			step2 = (<div>
							<div className="inputWrapper">
								<InputMask mask="99999" type="text" pattern="[0-9]*" inputMode="numeric" name="area" placeholder="Zip Code" onChange={this.changeHandler} value={this.state.area} className={`${this.state.highlight && (this.state.area.length < 1) ? "error": ""}`} />
								<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								    {close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
													<p>We will create your new phone number based on your zip code.</p>
												</div>)
									}
								</Popup>
							</div>
							<div className="inputWrapper">
								<input name="password" value={this.state.password} className={`${this.state.highlight && (this.state.password.length < 1) ? "error": ""}`} readOnly />
								<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
									{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
													<p>You can use this password to access your account to update payment or view activity. If you'd like to change it, please call us at <em>866-444-5404</em>.</p>
												</div>)
									}
								</Popup>
							</div>
						</div>);
		} else {
			step2 = (<div>
							<div className="inputWrapper">
								<InputMask mask="(999) 999-9999" type="tel" pattern="[0-9]*" inputMode="numeric" name="phone" placeholder="Phone Number" onChange={this.changeHandler} value={this.state.phone} className={`${this.state.highlight && (this.state.phone.length < 1) ? "error": ""}`} />
								<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
									{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
											        <p>Your current phone number</p>
												</div>)
									}
								</Popup>
							</div>
							<div className="inputWrapper">
								<input type="text" name="account" placeholder="Account Number (without spaces or dashes)" onChange={this.changeHandler} value={this.state.account} className={`${this.state.highlight && (this.state.account.length < 1) ? "error": ""}`} />
								<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
									{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
											        <p>Your old service provider account number without any spaces or dashes.</p>
												</div>)
									}
								</Popup>
							</div>
							<div className="inputWrapper">
								<input type="text" name="accountPass" placeholder="Account Password (without spaces or dashes)" onChange={this.changeHandler} value={this.state.accountPass} className={`${this.state.highlight && (this.state.accountPass.length < 1) ? "error": ""}`} />
								<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
									{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
											        <p>Password from your old service provider account without any spaces or dashes.</p>
												</div>)
									}
								</Popup>
							</div>
							<div className="inputWrapper">
								<input name="password" value={this.state.password} className={`${this.state.highlight && (this.state.password.length < 1) ? "error": ""}`} readOnly />
								<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
									{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
											        <p>You can use this password to access your account to update payment or view activity. If you'd like to change it, please call us at <em>866-444-5404</em>.</p>
												</div>)
									}
								</Popup>
							</div>
						</div>);
		}
		switch (step) {
			case 0:
				el = (
					<div className="step1">
						<h3>Step 1. Verify Phone</h3>
						<div className="inputWrapper">
							<InputMask mask="999999999999999" type="text" name="esn" pattern="[0-9]*" inputMode="numeric" placeholder="IMEI" onChange={this.changeHandler} value={this.state.esn} className={`${this.state.highlight && (this.state.esn.length < 1) ? "error": ""}`} />
							<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
											        <p>Find your IMEI by removing the battery and finding the 15 digit number.</p>
									<img src={ImeiHint} alt="IMEI Hint" />
												</div>)
									}
							</Popup>
						</div>
						<div className="inputWrapper">
							<InputMask mask="99999999999999999999" type="text" name="iccid" pattern="[0-9]*" inputMode="numeric" placeholder="Sim" onChange={this.changeHandler} value={this.state.iccid} className={`${this.state.highlight && (this.state.iccid.length < 1) ? "error": ""}`} />
							<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
									<p>Your sim number is the 20 digit number on your sim card.</p>
									<img src={SimHint} alt="Sim Hint" />
												</div>)
								}
							</Popup>
						</div>
						<div className="inputWrapper">
							<InputMask mask="(999) 999-9999" type="tel" name="callback" pattern="[0-9]*" inputMode="numeric" placeholder="Callback Number" onChange={this.changeHandler} value={this.state.callback} className={`${this.state.highlight && (this.state.iccid.length < 1) ? "error": ""}`} />
							<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
											        <p>Enter a callback so that we can reach you in case something goes wrong.</p>
												</div>)
								}
							</Popup>
						</div>
			    		<div className="actionWrapper">
				    		<a href="#" className="button" onClick={this.prev}>Back</a>
				    		<a href="#" className="button" onClick={this.next}>Next</a>
			    		</div>
					</div>
				)
				break;
			case 1:
				el = (
					<div className="step2">
						<h3>Step 2. Activate Phone</h3>
						<div className="tabs">
							<div className="tab-header">
								<a href="#" className={`button ${this.state.transfer ? "" : "active"}`} onClick={() => this.transfer(false)}>New Number</a>
								<a href="#" className={`button ${this.state.transfer ? "active" : ""}`} onClick={() => this.transfer(true)}>Transfer Number</a>
							</div>
						</div>
						{step2}
			    		<div className="actionWrapper">
				    		<a href="#" className="button" onClick={this.prev}>Back</a>
				    		<a href="#" className="button" onClick={this.next}>Next</a>
			    		</div>
					</div>
				)
				break;
			case 2:
				el = <Checkout 
						step="3" 
						success={this.success} 
						error={this.error}
						selectError={this.selectError}
						cardError={this.cardError} 
						chargeError={this.chargeError}
						loading={this.loading}
						stopLoading={this.stopLoading}
						mdn={this.state.mdn} 
						prev={this.prev}
						next={this.next} />
				break;
			case 3:
				if(!this.state.transfer) {
					el = (<div className="successWrapper step3">
							<img src={Success} alt="pending" />
							<h2>Success</h2>
							<p>Here is your new number. Please write it down.</p>
							<h4>{this.state.mdn}</h4>
							<p className="success-instructions">Now you will need to add a plan. In about 10 minutes you can try one of the following:
								<ul>
									<li>1. Dial 611 from your phone</li>
									<li>2. Call 866-444-5404 from any phone</li>
									<li>3. Use this portal to replenish your account</li>
								</ul>
							</p>
							
							<div className="actionWrapper">
								<a href="#" className="button" onClick={this.verify}>Close</a>
							</div>
						</div>);
				} else {
					el = (<div className="successWrapper">
							<img src={Pending} alt="pending" />
							<h2>Porting Phone</h2>
							<p>It can take up to 24 hours for your old service provider to complete porting your phone number.</p>
							<p>To complete your setup, please call us at <em>866-444-5404</em> once it's been transferred. Or you can come back to this kiosk to replenish your ported phone.</p>
						</div>);					
				}
				break;
			default:
				break;
		} 
	    return (
	    	<div className="formWrapper">
	    		{el}
	    		<Popup open={this.state.loading} className="loadingWrapper" onClose={this.loadingClose} closeOnDocumentClick={false} >
	    			<div className="wrapper">
		    			<div className="loadingIcon"></div>
		    			<p>{this.state.loadingMsg}</p>
	    			</div>
	    		</Popup>
	    		<Popup open={this.state.error} className="errorWrapper" onClose={this.errorClose}>
				    {close => (
		    			<div className="wrapper">
					        <a className="close" onClick={close}>
					          &times;
					        </a>
			    			<img src={ErrorIcon} alt="error" />
			    			<h2>Error</h2>
			    			{this.state.errMsg}
		    			</div>
				    )}
	    		</Popup>
	    	</div>
    	);
	}
}

export default New;
