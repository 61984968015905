import React from 'react';
import './App.css';
import Checkout from './Checkout.js';
import InputMask from "react-input-mask";
import Popup from "reactjs-popup";
import Success from './images/success.png';
import ErrorIcon from './images/error.png';
import SimHint from './images/sim-hint.jpg';
import SimHint2 from './images/sim-hint2.png';

class Replenish extends React.Component {
	constructor(props) {
		super(props);

		let mdn = true;
		if(props.activate) {
			mdn = false;
		}
		this.state = {
			loading: false,
			step: 0,
			password: '',
			error: false,
			warning: false,
			errMsg: '',
			phone: '',
			iccid: '8914800000',
			plansLoaded: false,
			plans: [],
			selectedPlan: '',
			amount: 0,
			planId: '',
			cc: '',
			exp: '',
			cvv: '',
			zip: '',
			autopay: false,
			highlight: false,
			byMdn: mdn,
			lastPlan: '',
			lastPlanCode: ''
		};
	}
	componentDidMount() {

	}

	//Click next
	next = () => {
		var step = this.state.step
		this.setState({loading: true})
		switch(step) {
			case 0:
				if(this.validate(step)){
					let url = "";
					if(this.state.byMdn) {
						let phone = this.state.phone.replace(/\D+/g, '');
	 					url = "https://pvc2.approvedlink.com/CVWebService/PhoneHandler.aspx?method=verification&phonenumber="+phone+"&secretcode=1234&login=tablet1!";
					} else {
						url = " https://pvc2.approvedlink.com/CVWebService/PhoneHandler.aspx?method=verificationesn&iccid="+this.state.iccid+"&secretcode=1234&login=tablet1!"
					}
					fetch(url)
						.then(res => res.text())
						.then(
							(result) => {
								console.log(result);
								if(result.search('=') != -1) {
									var beginStr = result.slice(0, result.indexOf(',LastPlanName'));
									var planName = result.slice(result.indexOf(',LastPlanName')).replace(",LastPlanName=", "");
									this.setState({lastPlan: planName});
									var str = '{"'+beginStr.split("=").join("\":\"")+'"}';

									var beginStrCode = result.slice(0, result.indexOf(',LastPlanCode'));
									var planCode = result.slice(result.indexOf(',LastPlanCode'), result.indexOf(",LastPlanName")).replace(",LastPlanCode=", "");
									this.setState({lastPlanCode: planCode});

									var json = JSON.parse(str.split(',').join('","'));

									if(json.LineStatus === 'ACTIVE' && json.CODE_STATUS === 'Verified'){
										console.log(json.MDN);
										let detailsUrl = "https://pvc2.approvedlink.com/CVWebService/PhoneHandler.aspx?method=checkbalance&phonenumber="+json.MDN+"&secretcode=1234&login=tablet1!";
										fetch(detailsUrl)
										.then(res => res.text())
										.then(
											(detailsResult) => {
												console.log('here');
												console.log(detailsResult);
												if(detailsResult.length && detailsResult !== "Login failed") {
													var detailsStr = '{"'+detailsResult.split("=").join("\":\"")+'"}';
													var detailsJson = JSON.parse(detailsStr.split(',').join('","'));
	
													if(detailsResult.includes("DUE_DATE") && detailsJson.DUE_DATE != "NULL") {
														this.setState({errMsg: 'Replenishing the account before the end of the billing cycle will restart the plan, and all remaining days will be lost. Do you wish to continue?'});
														this.setState({loading: false, warning: true});
													} else {
														this.setState({loading: false});
														this.setState({step: 1});
													}
												} else {
													this.setState({errMsg: 'Error getting account details'});
													this.setState({loading: false, error: true});
												}
											},
											(error) => {
												this.setState({errMsg: 'There was an error getting your information. Contact us at <em>866-444-5404</em> for more information.'});
												this.setState({loading: false, error: true});
											}
										)										
									} else {
										this.setState({errMsg: 'Line is not active. Contact us at <em>866-444-5404</em> for more information.'});
										this.setState({loading: false, error: true});
									}	

								} else {
									this.setState({errMsg: 'Line is not active. Try again or contact us at 866-444-5404 for more information.'});
									this.setState({loading: false, error: true});
								}
							},
							(error) => {
								this.setState({errMsg: 'There was an error when trying to verify your SIM. Try again or contact us at 866-444-5404 for more information.'});
								this.setState({loading: false, error: true});
								console.log(error);
							}
						)	
				} else {
					this.setState({loading: false});
					this.setState({error: true});
				}
				break;
			case 1:
				if(this.validate(step)){
					this.setState({loading: false});
					this.setState({step: 2})
				} else {
					this.setState({loading: false});
					this.setState({error: true});
				}
				break;
			default:
				break;
		}
	}

	// Validate step
	validate = (step) => {
		let returnVal = true;
		let err = [];
		switch(step) {
			case 0:
				if(this.state.byMdn) {
					if(this.state.phone.length < 10) {
						returnVal = false;
					}
				} else {
					if(this.state.iccid.length < 20) {
						returnVal = false;
					}
				}
				if(this.state.password.length < 4) {
					returnVal = false;
				}	
				if(!returnVal) {
					this.setState({highlight: true, errMsg: 'Oops. You forgot to fill out the all fields completely.'});
				}
				break;
			default:
				break;
		}
		return returnVal;
	}

	// Close loading
	loadingClose = () => {
		this.setState({loading: false});
	}

	// Close error 
	errorClose = () => {
		this.setState({error: false});
	}

	// Previous button
	prev = () => {
		if(this.state.step > 0)
			this.setState({step: this.state.step - 1});
		else 
			this.props.changePage()
	}

	// Change input
	changeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		if(nam == 'autopay') {    
			val = event.target.checked;
		}
		this.setState({[nam]: val});
	}

	// Set verify by MDN
	byMdn = (isMdn) => {
		console.log('isMdn');
		console.log(isMdn);
		this.setState({byMdn: isMdn})
	}

	// Success handler 
	success = () => {
		console.log('success');
	}

	// Warning actions
	warningYes = () => {
		this.setState({loading: false});
		this.setState({warning: false, errorMsg: ''});
		this.setState({step: 1});
	}

	warningNo = () => {
		this.props.changePage()
	}

	// Error handlers
	selectError = () => {
		this.setState({errMsg: 'Please select a plan.'});	
		this.setState({error: true})
	}
	cardError = () => {
		this.setState({errMsg: 'Oops. You forgot to fill out the highlighted fields.'});
		this.setState({error: true})
	}
	chargeError = () => {
		this.setState({errMsg: 'We could not charge your card. Please try again or call us at 866-444-5404.'});
		this.setState({error: true})
	}
	error = () => {
		this.setState({errMsg: 'Uh oh. There was an unidentified error. Please try again or call us at 866-444-5404.'});
		this.setState({error: true})
	}

	// Loading Handlers
	loading = () => {
		this.setState({loading: true})
	}
	stopLoading = () => {
		this.setState({loading: false})
	}

	// Display page
	render() {
		var step = this.state.step;

		var el = '';

		var step0 = '';
		if(this.state.byMdn) {
			step0 = (
					<div>
						<div className="inputWrapper">
							<InputMask mask="(999) 999-9999" name="phone" pattern="[0-9]*" inputMode="numeric" placeholder="Phone Number" onChange={this.changeHandler} value={this.state.phone} className={`${this.state.highlight && (this.state.phone.length < 10) ? "error": ""}`} />
							<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								{close => (<div>
							        <a className="close" onClick={close}>
							          &times;
							        </a>
							        <p>Enter your phone number.</p>
				    			</div>)}
							</Popup>
						</div>
						<div className="inputWrapper">
							<InputMask mask="9999" type="text" name="password" pattern="[0-9]*" inputMode="numeric" placeholder="Your account password" onChange={this.changeHandler} value={this.state.password} className={`${this.state.highlight && (this.state.password.length < 4) ? "error": ""}`}/>
							<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								{close => (<div>
							        <a className="close" onClick={close}>
							          &times;
							        </a>
							        <p>This is the password you created when starting the activation process.</p>
				    			</div>)}
							</Popup>
						</div>
					</div>
			)
		} else {
			step0 =(<div>
						<div className="inputWrapper">
							<InputMask mask="99999999999999999999" type="text" name="iccid" pattern="[0-9]*" inputMode="numeric" placeholder="Sim" onChange={this.changeHandler} value={this.state.iccid} className={`${this.state.highlight && (this.state.iccid.length < 1) ? "error": ""}`} />
							<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
									<p>Your sim number is the 20 digit number on your sim card.</p>
									<img src={SimHint} alt="Sim Hint" />
									<img src={SimHint2} alt="Sim Hint" />
												</div>)
								}
							</Popup>
						</div>
						<div className="inputWrapper">
							<InputMask mask="9999" type="text" name="password" pattern="[0-9]*" inputMode="numeric" placeholder="Your account password" onChange={this.changeHandler} value={this.state.password} className={`${this.state.highlight && (this.state.password.length < 4) ? "error": ""}`}/>
							<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								{close => (<div>
							        <a className="close" onClick={close}>
							          &times;
							        </a>
							        <p>This is the password you created when starting the activation process. If you didn't set one it was automatically set to 1234.</p>
				    			</div>)}
							</Popup>
						</div>
					</div>);

		}

		switch (step) {
			case 0:
				el = (
					<div className="step1">
						<h3>Step 1. Verify</h3>
						<div className="tabs">
							<div className="tab-header">
								<a href="#" className={`button ${this.state.byMdn ? "active" : ""}`} onClick={() => this.byMdn(true)}>Verify by Phone Number</a>
								<a href="#" className={`button ${this.state.byMdn ? "" : "active"}`} onClick={() => this.byMdn(false)}>Verify by SIM</a>
							</div>
						</div>
						{step0}
			    		<div className="actionWrapper">
				    		<a href="#" className="button" onClick={this.prev}>Back</a>
				    		<a href="#" className="button" onClick={this.next}>Next</a>
			    		</div>
					</div>
				)
				break;
			case 1:
				var phone = this.state.phone.replace(/\D+/g, '');
				el = <Checkout 
						step="2" 
						success={this.success} 
						error={this.error}
						selectError={this.selectError}
						cardError={this.cardError} 
						chargeError={this.chargeError}
						loading={this.loading}
						stopLoading={this.stopLoading}
						curPlan={this.state.lastPlan}
						curPlanCode={this.state.lastPlanCode}
						mdn={phone} 
						prev={this.prev}
						next={this.next} />
				break;
			case 2:
				el = (<div className="successWrapper">
						<img src={Success} alt="success" />
						<h2>Congrats!</h2>
						<p>Your account has been replenished. You can now enjoy your phone for the next month.</p>
					</div>);
				break;
			default:
				break;
		} 
	    return (
	    	<div className="formWrapper">
	    		{el}
	    		<Popup open={this.state.loading} className="loadingWrapper" onClose={this.loadingClose} closeOnDocumentClick={false} >
	    			<div className="loadingIcon"></div>
	    		</Popup>
	    		<Popup open={this.state.error} className="errorWrapper" onClose={this.errorClose}>
		    		{close => (<div className="wrapper">
					        <a className="close" onClick={close}>
					          &times;
					        </a>
			    			<img src={ErrorIcon} alt="error" />
			    			<h2>Error</h2>
			    			{this.state.errMsg}
		    			</div>)}
	    		</Popup>
	    		<Popup open={this.state.warning} className="errorWrapper warningWrapper" onClose={this.errorClose}>
		    		{close => (<div className="wrapper">
					        <a className="close" onClick={close}>
					          &times;
					        </a>
			    			<img src={ErrorIcon} alt="error" />
			    			<h2>Warning</h2>
			    			{this.state.errMsg}
				    		<div className="actionWrapper">
					    		<a href="#" className="button blue" onClick={this.warningNo}>No</a>
					    		<a href="#" className="button" onClick={this.warningYes}>Yes</a>
				    		</div>
		    			</div>)}
	    		</Popup>
	    	</div>
    	);
	}
}

export default Replenish;
