import React from 'react';
import './App.css';
import Home from './Home.js';
import New from './New.js';
import Port from './Port.js';
import Replenish from './Replenish.js';
import View from './View.js';
import Header from './Header.js';

class App extends React.Component {
	constructor() {
		super();
		this.state = {page: "home", isActivate: false};
	}
	changePage = (page) => {
		this.setState({page: page});
	}
	changeReplenish = () => {
		this.setState({isActivate: true});
		this.setState({page: "replenish"});
	}
	changeHome = () => {
		this.setState({page: "home"});
	}
	render() {
		let page = '';
		let isHome = false;
		if(this.state.page === "home") {
			page = '';
			isHome = true;
		} else if (this.state.page === "new") {
			page = <New changePage={this.changeReplenish} />
		} else if (this.state.page === "check") {
			page = <Port />
		} else if (this.state.page === "replenish") {
			page = <Replenish changePage={this.changeHome} activate={this.state.isActivate}/>
		} else if (this.state.page === "view") {
			page = <View changePage={this.changePage} />
		} else {
			page = <h1>{this.state.page}</h1>;
		}

		return (
			<div className={`App ${isHome ? "home" : ""}`}>
				<div className="wrapper">
					<Header />
					<Home changePage={this.changePage} page={this.state.page} />
					{page}
				</div>
			</div>
		);	
	}
}

export default App;
