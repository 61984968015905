import React from 'react';
import './App.css';
import Checkout from './Checkout.js';
import InputMask from "react-input-mask";
import Popup from "reactjs-popup";
import Success from './images/success.png';
import ErrorIcon from './images/error.png';

class Port extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			step: 0,
			password: '',
			error: false,
			errMsg: '',
			phone: '',
			plansLoaded: false,
			plans: [],
			selectedPlan: '',
			amount: 0,
			planId: '',
			cc: '',
			exp: '',
			cvv: '',
			zip: '',
			autopay: false,
			highlight: false
		};
	}
	componentDidMount() {

	}

	//Click next
	next = () => {
		var step = this.state.step
		this.setState({loading: true})
		switch(step) {
			case 0:
				if(this.validate(step)){
					let phone = this.state.phone.replace(/\D+/g, '');
					let url = "https://pvc2.approvedlink.com/CVWebService/PhoneHandler.aspx?method=verification&phonenumber="+phone+"&secretcode=1234&login=tablet1!";
					fetch(url)
						.then(res => res.text())
						.then(
							(result) => {
								console.log(result);

								if(result.search('=') != -1) {
									var str = '{"'+result.split("=").join("\":\"")+'"}';
									try {
										var json = JSON.parse(str.split(',').join('","'));

										if(json.LineStatus === 'ACTIVE' && json.CODE_STATUS === 'Verified'){
											this.setState({loading: false});
											this.setState({step: 1});
										} else {
											this.setState({errMsg: 'Line is not active. Contact us at 866-444-5404 for more information.'});
											this.setState({loading: false, error: true});
										}	
									} catch (error) {
										this.setState({errMsg: 'The line was not found. Try entering your phone number again or call us at 866-444-5404.'});
										this.setState({loading: false, error: true});									
									}
								} else {
										this.setState({errMsg: 'The line was not found. Try entering your phone number again or call us at 866-444-5404.'});
										this.setState({loading: false, error: true});									
								}
							},
							(error) => {
								console.log(error);
								this.setState({loading: false});
							}
						)	
				} else {
					this.setState({loading: false});
					this.setState({error: true});
				}
				break;
			case 1:
				if(this.validate(step)){
					this.setState({loading: false});
					this.setState({step: 2})
				} else {
					this.setState({loading: false});
					this.setState({error: true});
				}
				break;
			default:
				break;
		}
	}

	// Validate step
	validate = (step) => {
		let returnVal = true;
		let err = [];
		switch(step) {
			case 0:
				if(this.state.phone.length < 10) {
					returnVal = false;
				}
				if(this.state.password.length < 4) {
					returnVal = false;
				}
				if(!returnVal) {
					this.setState({highlight: true, errMsg: 'Oops. You forgot to fill out the highlighted fields completely.'});
				}
				break;
			default:
				break;
		}
		return returnVal;
	}

	// Close loading
	loadingClose = () => {
		this.setState({loading: false});
	}

	// Close error 
	errorClose = () => {
		this.setState({error: false});
	}

	// Previous button
	prev = () => {
		if(this.state.step > 0)
			this.setState({step: this.state.step - 1});
	}

	// Change input
	changeHandler = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		if(nam == 'autopay') {    
			val = event.target.checked;
		}
		this.setState({[nam]: val});
	}

	// Transfer / Activate toggle
	transfer = (trans) => {
		this.setState({transfer: trans})
	}

	// Select a plan
	choosePlan = (plan, amount, planId) => {
		this.setState({selectedPlan: plan, amount: amount, planId: planId})
	}


	// Success handler 
	success = () => {
		console.log('success');
	}

	// Error handlers
	selectError = () => {
		this.setState({errMsg: 'Please select a plan.'});	
		this.setState({error: true})
	}
	cardError = () => {
		this.setState({errMsg: 'Oops. You forgot to fill out the highlighted fields.'});
		this.setState({error: true})
	}
	chargeError = () => {
		this.setState({errMsg: 'We could not charge your card. Please try again or call us at 866-444-5404.'});
		this.setState({error: true})
	}
	error = () => {
		this.setState({errMsg: 'Uh oh. There was an unidentified error. Please try again or call us at 866-444-5404.'});
		this.setState({error: true})
	}

	// Loading Handlers
	loading = () => {
		this.setState({loading: true})
	}
	stopLoading = () => {
		this.setState({loading: false})
	}
	
	// Display page
	render() {
		var step = this.state.step;

		var el = '';
		switch (step) {
			case 0:
				el = (
					<div className="step1">
						<h3>Step 1. Login</h3>
						<div className="inputWrapper">
							<InputMask mask="(999) 999-9999" name="phone" pattern="[0-9]*" inputMode="numeric" placeholder="Phone Number" onChange={this.changeHandler} value={this.state.phone} className={`${this.state.highlight && (this.state.phone.length < 10) ? "error": ""}`} />
							<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
													<p>Enter your ported phone number.</p>
												</div>)
									}
							</Popup>
						</div>
						<div className="inputWrapper">
							<InputMask mask="9999" type="text" name="password" pattern="[0-9]*" inputMode="numeric" placeholder="Your account password" onChange={this.changeHandler} value={this.state.password} className={`${this.state.highlight && (this.state.password.length < 4) ? "error": ""}`}/>
							<Popup trigger={<button className="trigger"> ?</button>} modal closeOnDocumentClick>
								{close => (<div>
											        <a className="close" onClick={close}>
											          &times;
											        </a>
													<p>This is the password you created when starting the activation process.</p>
												</div>)
									}
							</Popup>
						</div>
			    		<div className="actionWrapper">
				    		<a href="#" className="button" onClick={this.prev}>Back</a>
				    		<a href="#" className="button" onClick={this.next}>Next</a>
			    		</div>
					</div>
				)
				break;
			case 1:
				var phone = this.state.phone.replace(/\D+/g, '');
				el = <Checkout 
						step="2" 
						success={this.success} 
						error={this.error}
						selectError={this.selectError}
						cardError={this.cardError} 
						chargeError={this.chargeError}
						loading={this.loading}
						stopLoading={this.stopLoading}
						mdn={phone} 
						prev={this.prev}
						next={this.next} />
				break;
			case 2:
				el = (<div className="successWrapper">
						<img src={Success} alt="success" />
						<h2>Congrats!</h2>
						<p>Your phone is now activated and good to use.</p>
						<p>You can log in to view your activity using your phone number and the password created before.</p>
					</div>);
				break;
			default:
				break;
		} 
	    return (
	    	<div className="formWrapper">
	    		{el}
	    		<Popup open={this.state.loading} className="loadingWrapper" onClose={this.loadingClose} closeOnDocumentClick={false} >
	    			<div className="loadingIcon"></div>
	    		</Popup>
	    		<Popup open={this.state.error} className="errorWrapper" onClose={this.errorClose}>
	    			{close => (<div className="wrapper">
					        <a className="close" onClick={close}>
					          &times;
					        </a>
			    			<img src={ErrorIcon} alt="error" />
			    			<h2>Error</h2>
			    			{this.state.errMsg}
		    			</div>)}
	    		</Popup>
	    	</div>
    	);
	}
}

export default Port;
